import React, { useState } from "react";
import Heading from "../../shared/heading";
import Buttoon from "../buttoon";

const PricingHeader = ({ active, setActive }) => {
  return (
    <>
      <div className="mt-5 pricingHeader">
        <Heading
          headingtext="Pricing"
          paraText="Visually distinguish between the Basic and Premium plans with a brief overview of key features. Include a CTA button for Start Your Free Trial under each plan to encourage conversions."
        />
        <div className="buttonWrap">
          <Buttoon
            className={`btn ${active === "Monthly" ? "btn-primary" : "btn"}`}
            minWidth={68}
            minHeight={39}
            onClick={() => setActive("Monthly")}
            text="Monthly"
          />
          <Buttoon
            className={`btn ${active === "Annually" ? "btn-primary" : "btn"}`}
            minWidth={68}
            minHeight={39}
            onClick={() => setActive("Annually")}
            text="Annually"
          />
        </div>
      </div>
    </>
  );
};

export default PricingHeader;

