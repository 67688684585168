import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import Buttoon from "../../components/buttoon";
import Input from "../../shared/input";
import axios from "axios";
import Swal from "sweetalert2";

const ViewFeedback = ({ isFeedbackModal, setIsFeedbackModal, feedbackId, updateFeedbackList }) => {
  const handleCancel = () => {
    setIsFeedbackModal(false);
  };

  const [username, setUsername] = useState("");
  const [resolutionComment, setResolutionComment] = useState("");
  const [status, setStatus] = useState("New");

  useEffect(() => {
    const fetchFeedbackDetails = async () => {
      if (!feedbackId) return;

      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin-route/feedback/${feedbackId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data) {
          setUsername(response.data.userName);
          setResolutionComment(response.data.resolutionComment || "");
          setStatus(response.data.status || "New");
        }
      } catch (error) {
        console.error("Error fetching feedback details:", error);
      }
    };

    if (isFeedbackModal) {
      fetchFeedbackDetails();
    }
  }, [feedbackId, isFeedbackModal]);

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/admin-route/feedback/update`,
        {
          feedbackId,
          status,
          resolutionComment,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Feedback status updated successfully',
      });

      updateFeedbackList({
        feedbackId,
        userName: username,
        feedback: response.data.feedback,
        status,
        date: response.data.date,
      });

      setResolutionComment("");
      setStatus("New");
      
      handleCancel();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while updating the feedback status',
      });
      console.error("Error updating feedback status:", error);
    }
  };

  return (
    <>
      <Modal
        open={isFeedbackModal}
        className="credit-modal"
        centered={true}
        width={890}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="modal-content">
          <div className="pass-modal-header w-100">
            <h4 className="fw-bold">View Feedback</h4>
          </div>
          <div className="pass-modal-content">
            <div className="c-pass-div h-center justify-content-start flex-column gap-2">
              <label className=" fw-bold" htmlFor="">
                Username
              </label>
              <Input
                maxWidth="100%"
                width="100%"
                height="40px"
                type="text"
                className="curnt-pass"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <div className="c-pass-div h-center justify-content-start flex-column gap-2 mt-4">
              <label className=" fw-bold" htmlFor="">
                Resolution Comment
              </label>
              <textarea
                style={{ backgroundColor: "#F9FAFC" }}
                className="textAreaa"
                name=""
                id=""
                value={resolutionComment}
                onChange={(e) => setResolutionComment(e.target.value)}
              ></textarea>
            </div>

            <div className="d-flex gap-3 mt-3">
              <label className="inProgress d-flex gap-2">
                <p className="fw-semibold">In Progress</p>
                <input
                  type="radio"
                  name="status"
                  value="In Progress"
                  checked={status === "In Progress"}
                  onChange={(e) => setStatus(e.target.value)}
                />
              </label>
              <label className="resolved d-flex gap-2">
                <p className="fw-semibold">Resolved</p>
                <input
                  type="radio"
                  name="status"
                  value="Resolved"
                  checked={status === "Resolved"}
                  onChange={(e) => setStatus(e.target.value)}
                />
              </label>
            </div>

            <div className="h-center">
              <Buttoon
                className="btn btn-primary mt-5 m-auto"
                minWidth={166}
                minHeight={43}
                text="Save"
                onClick={handleSave}
              ></Buttoon>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewFeedback;
