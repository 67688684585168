import React, { useState, useEffect } from "react";
import Buttoon from "../components/buttoon";
import logoo from "../assets/images/home/logoo.svg";
import finalLogo from "../assets/icons/landingLogo.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import cross2 from "../assets/icons/icons8-cross-25.png";
import menu from "../assets/icons/menu.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import { scroller } from "react-scroll";

const Navbar = () => {
  const [show, setShow] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login state
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [chatId, setchatId] = useState();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const loginChat = localStorage.getItem("chat_id");
    setchatId(loginChat);
    if (token) {
      setIsLoggedIn(true); // User is logged in
    } else {
      setIsLoggedIn(false); // User is not logged in
    }
  }, []);

  const navigateToHomeSection = (section, offset = 0) => {
    navigate("/");
    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: offset,
      });
    }, 100);
  };

  return (
    <>
      <div className="nav-wrapper">
        <div className="container">
          <div className="innerNav v-center justify-content-between">
            <NavLink to="/" className="cursor-pointer">
              <div className="navLogo">
                <img src={finalLogo} alt="" />
              </div>
            </NavLink>

            <div className="navContent">
              <ul className="v-center h-center gray-text fw-bold text-med ">
                <NavLink className="text-black cursor-pointer" to="/">
                  <li>Product</li>
                </NavLink>
                <a className="text-black cursor-pointer">
                  <li
                    onClick={() => {
                      navigateToHomeSection("feature", -100);
                    }}
                  >
                    Features
                  </li>
                </a>
                {/* <a className="text-black cursor-pointer">
                  <li
                    onClick={() => navigateToHomeSection("Testimonials", -100)}
                  >
                    Testimonials
                  </li>
                </a> */}
                <NavLink className="text-black cursor-pointer" to="/pricing">
                  <li>Pricing</li>
                </NavLink>
                <a className="text-black cursor-pointer">
                  <li onClick={() => navigateToHomeSection("faq", -100)}>
                    FAQ’s
                  </li>
                </a>
              </ul>
            </div>

            <div className="navBtns">
              {isLoggedIn ? (
                <Link to={`/dashboard/${chatId}`}>
                  <Buttoon
                    className="btn btn-primary"
                    minWidth={68}
                    minHeight={39}
                    text="Dashboard"
                  ></Buttoon>
                </Link>
              ) : (
                <>
                  <Link to="/login">
                    <Buttoon
                      className="btn btn-secondary"
                      minWidth={68}
                      minHeight={39}
                      text="Sign In"
                    ></Buttoon>
                  </Link>
                  <Link to="/signup">
                    <Buttoon
                      className="btn btn-primary"
                      minWidth={68}
                      minHeight={39}
                      text="Sign Up"
                    ></Buttoon>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-navbar">
        <NavLink className="d-flex" to="/">
          <img className="mobileLogo" src={finalLogo} alt="" />
        </NavLink>
        <button onClick={handleShow} className="menu-btn">
          <img src={menu} alt="Menu" />
        </button>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <NavLink to="/">
                <img src={finalLogo} alt="..." className="logostyle" />
              </NavLink>
            </Offcanvas.Title>
            <img onClick={handleClose} src={cross2} alt="Close" />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="p-0 gray-text fw-bold text-med">
              <li
                onClick={() => {
                  navigate("/");
                  setShow(false);
                }}
              >
                <NavLink className="active" to="/home">
                  Product
                </NavLink>
              </li>
              {/* <li
                onClick={() => {
                  navigateToHomeSection("Testimonials", -100);
                  setShow(false);
                }}
              >
                <a>Testimonials</a>
              </li> */}
              <li
                onClick={() => {
                  navigateToHomeSection("feature", -100);
                  setShow(false);
                }}
              >
                <a>Features</a>
              </li>
              <li>
                <NavLink to="/pricing">Pricing</NavLink>
              </li>
              <li
                onClick={() => {
                  navigateToHomeSection("faq", -100);
                  setShow(false);
                }}
              >
                <a>FAQ's</a>
              </li>
            </ul>

            <div className="d-flex gap-3 flex-column">
              {/* Conditional Rendering: If logged in, show 'Dashboard' button, otherwise show 'Sign In' and 'Sign Up' */}
              {isLoggedIn ? (
                <Link to="/dashboard">
                  <Buttoon
                    className="btn btn-primary"
                    minWidth={68}
                    minHeight={39}
                    text="Dashboard"
                  ></Buttoon>
                </Link>
              ) : (
                <>
                  <Link to="/login">
                    <Buttoon
                      className="btn btn-secondary"
                      minWidth={68}
                      minHeight={39}
                      text="Sign In"
                    ></Buttoon>
                  </Link>
                  <Link to="/signup">
                    <Buttoon
                      className="btn btn-primary"
                      minWidth={68}
                      minHeight={39}
                      text="Sign Up"
                    ></Buttoon>
                  </Link>
                </>
              )}
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default Navbar;
