import React, { useState } from "react";
import { Modal } from "antd";
import PricingUpgrade from "../../components/pricing/pricingUpgarde";

const UpgradePlans = ({
  isUpgradeModalOpen,
  setIsUpgradeModalOpen,
  active,
  setActive,
}) => {
  //   const [selectedOption, setSelectedOption] = useState("");
  const handleOk = () => {
    setIsUpgradeModalOpen(false);
  };

  const handleCancel = () => {
    setIsUpgradeModalOpen(false);
  };
  const handleSelectChange = (event) => {
    setActive(event.target.value);
  };
  //   const handleChange = (event) => {
  //     setSelectedOption(event.target.value);
  //     const value = event.target.value;
  //     setSelectedOption(value);
  //   };

  return (
    <>
      <Modal
        title={<h4 className=" ps-4 mt-0 fw-bold">Upgrade Plans</h4>}
        open={isUpgradeModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={837}
        centered={true}
      >
        <div className="upgrade-content m-0 pt-0">
          <div className=" d-flex justify-content-between ps-4 pe-4 upgradePriceContent">
            <p className="fw-semibold">
              You get unlimited access to ChatKYC with both plans. Cancel
              anytime
            </p>
            <p className="upgrade-text">
              Save <span className="primary"> 30% </span> Annually
            </p>
            {/* <select
              id="options"
              //   value={selectedOption}
              //   onChange={handleChange}
              className="upgrade-select"
            > */}
            <select
              id="options"
              value={active}
              onChange={handleSelectChange}
              className="upgrade-select fw-bold"
            >
              <option className="fw-bold" value="annually">
                Annually
              </option>
              <option className="fw-bold" value="monthly">
                Monthly
              </option>
            </select>
          </div>
          <hr />
          <div className="upgrade-pricing">
            <PricingUpgrade active={active} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpgradePlans;
