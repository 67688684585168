import React, { useEffect } from "react";
import Navbar from "../shared/navbar";
import PricingHeader from "../components/pricing/pricingHeader";
import PricingCardItem from "../components/pricing/pricingCardItem";
import Footer from "../shared/footer";

const PricingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pricing">
      <div className="container">
        <Navbar />
        <PricingCardItem />
        <div className="mt-5">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
