import React, { useState ,useEffect } from "react";
import Sidebar from "../components/sidebar";
import { Outlet,useLocation  } from "react-router-dom";
import Dashnavbar from "../components/dashnavbar";
import { useNavigate } from 'react-router-dom';
import Chatbot from "./chatbot";
import { Icon } from "@iconify/react";
import verifyUser from "../middleware/verifyUser";

const Dashboard = () => {
 
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };


  const location = useLocation();
  const hideChatbotPaths = ["/settings", "/settings/profile", "/settings/account"];
  const shouldShowChatbot = !hideChatbotPaths.includes(location.pathname);
  const navigate = useNavigate();
  const isUser = verifyUser();

  useEffect(() => {
    if (!isUser) {
      navigate('/login');
    }
  }, [isUser, navigate]);

  if (!isUser) {
    return null;
  }
  return (
    <>
      <div className="Dashboard-main-container d-flex overflow-x-hidden">
        <div className="left-content">
          <Sidebar isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
        </div>

        <Icon icon="ion:menu" width={20} height={20} onClick={toggleDrawer} className="dashboardMenu d-none"/>

        <div className="right_content">
          <div className="page-dom">
            <Outlet />
          </div>
        </div>
      </div>
      {shouldShowChatbot && <Chatbot />}
    </>
  );
};

export default Dashboard;
