import React from "react";

const Heading = (props) => {
  return (
    <>
      <div className="headingContainer">
        <h1 className="text-center fw-bold">{props.headingtext}</h1>
        <p className="text-large fw-medium text-center">{props.paraText}</p>
      </div>
    </>
  );
};

export default Heading;
