import React, { useState } from "react";
import Buttoon from "../../components/buttoon";
import axios from "axios";
import Swal from "sweetalert2";

const UserFeedback = () => {
  const [feedback, setFeedback] = useState("");
  const [error, setError] = useState("");

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    
    const hasValidContent = /[A-Za-z0-9]/.test(feedback);

    if (!feedback.trim() || !hasValidContent) {
      setError("Feedback is required and cannot be empty or spaces only.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/createfeedback`,
        { feedback },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Feedback Submitted",
        text: "Your feedback has been submitted successfully.",
      });

      setFeedback("");
      setError("");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text:
          error.response?.data?.message ||
          "An error occurred while submitting your feedback.",
      });
    }
  };

  return (
    <div className="userFeedbackContainer">
      <p className="feedbackText ps-3 fw-semibold">Give Your Feedback</p>
      <p className="ps-3 pb-3 fw-semibold">
        Please leave any thoughts, comments or suggestions
      </p>
      <textarea
        className="textArea"
        value={feedback}
        onChange={handleFeedbackChange}
      />
      {error && <p className="error-message mt-1" style={{ color: "red" }}>{error}</p>}
      <Buttoon
        className="btn btn-primary mt-4 m-auto d-flex h-center v-center"
        minWidth={183}
        minHeight={43}
        text="Submit"
        onClick={handleSubmit}
      />
    </div>
  );
};

export default UserFeedback;
