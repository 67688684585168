import React, { useEffect, useState } from "react";
import Buttoon from "../components/buttoon";
import pageIcon from "../assets/icons/mm.svg";
import DashboardChatArea from "../components/messagebox";
import axios from "axios";
import { Link } from "react-router-dom";
import mammoth from "mammoth";
import { useLocation } from "react-router-dom";

const AmlPolicy = () => {
  const [convertedFiles, setConvertedFiles] = useState({});
  const [documentText, setDocumentText] = useState();
  const location = useLocation();
  const { savedDocument, chatId, chatName } = location.state || {};

  const convertDocxToText = async (savedDocument) => {
    try {
      if (
        !savedDocument ||
        typeof savedDocument !== "string" ||
        !savedDocument.startsWith("http")
      ) {
        console.error("Invalid savedDocument URL:", savedDocument);
        return;
      }

      const response = await axios.get(savedDocument, {
        responseType: "blob",
      });
      const arrayBuffer = await response.data.arrayBuffer();

      const result = await mammoth.extractRawText({ arrayBuffer });
      setDocumentText(result.value);
    } catch (error) {
      console.error("Error converting .docx file to text:", error);
    }
  };

  useEffect(() => {
    const handleFileConversion = async () => {
      if (savedDocument && chatId) {
        const text = await convertDocxToText(savedDocument);
        setConvertedFiles((prev) => ({ ...prev, [chatId]: text }));
      }
    };
    handleFileConversion();
  }, [savedDocument, chatId]);

  const parseMarkdown = (text) => {
    if (typeof text !== "string") return "";

    text = text.replace(
      /^# (.+)\s*$/gm,
      '<h1 style="color: black; font-weight: bold;">$1</h1>'
    );
    text = text.replace(
      /^### (.+)\s*$/gm,
      '<h3 style="color: black; font-weight: bold;">$1</h3>'
    );
    text = text.replace(
      /"(.*?)"/g,
      '<strong style="color: black;">"$1"</strong>'
    );
    text = text.replace(/\n/g, "<br/>");

    return text;
  };

  return (
    <>
      <div className="chat pe-4">
        <div
          style={
            {
              // position: "fixed",
              // top: "20px",
              // width: "100%",
              // maxWidth: "75%",
              // left: "45%",
              // transform: "translate(-30% , -26%)",
              // zIndex: "9999",
            }
          }
        >
          <div className="kycWriting d-flex justify-content-between align-items-center fixed">
            <h3>{chatName}</h3>
            <Buttoon
              className="btn btn-primary"
              minWidth={208}
              minHeight={39}
              text="Save Document"
            />
          </div>
          <hr />
        </div>
        <div className="chat-data d-flex flex-column justify-content-between mt-5">
          <div className="chattname">
            <img className="pageIcon" src={pageIcon} alt="PageIcon" />
            <div className="chatting">
              <h4>ChatKYC</h4>
              <h5>Document Completeness:</h5>
              <p
                className=""
                dangerouslySetInnerHTML={{
                  __html: parseMarkdown(documentText),
                }}
              />
              <div className="d-flex gap-2 mt-4 ">
                <Link
                  style={{
                    backgroundColor: "#0085ff",
                    width: "auto",
                    padding: "10px",
                    color: "white",
                    borderRadius: "10px",
                    fontWeight: "600",
                  }}
                  to={`/dashboard/${chatId}`}
                >
                  More Chat?
                </Link>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </>
  );
};

export default AmlPolicy;

// import React, { useEffect, useState } from "react";
// import Buttoon from "../components/buttoon";
// import userIcon from "../assets/icons/userIcon.svg";
// import pageIcon from "../assets/icons/mm.svg";
// import document from "../assets/icons/contract.svg";
// import DashboardChatArea from "../components/messagebox";
// import { Icon } from "@iconify/react";
// import chatBot from "../assets/icons/chotBotIcon.svg";
// import uploadIcon from "../assets/icons/uploadCloud.svg";
// import cross from "../assets/icons/cross.svg";
// import { InboxOutlined } from "@ant-design/icons";
// import msgSendIcon from "../assets/icons/msgSendIcon.svg";
// import greenTick from "../assets/icons/greenTick.png";
// import mammoth from 'mammoth';
// import axios from 'axios';
// import { useLocation } from 'react-router-dom';

// const AmlPolicy = () => {
//   const [convertedFiles, setConvertedFiles] = useState({});
//   const [documentText , setDocumentText] = useState();
//   const location = useLocation();
//   const { savedDocument, chatId ,chatName } = location.state || {};

//   const [clickedIcons, setClickedIcons] = useState({
//     like: false,
//     heart: false,
//     dislike: false,
//   });

//   const handleClick = (iconName) => {
//     setClickedIcons({
//       like: false,
//       heart: false,
//       dislike: false,
//       [iconName]: true,
//     });
//   };

//   const convertDocxToText = async (savedDocument) => {
//     try {
//       if (!savedDocument || typeof savedDocument !== 'string' || !savedDocument.startsWith('http')) {
//         console.error('Invalid savedDocument URL:', savedDocument);
//         return;
//       }

//       const response = await axios.get(savedDocument, {
//         responseType: 'blob',
//       });
//       const arrayBuffer = await response.data.arrayBuffer();

//       const result = await mammoth.extractRawText({ arrayBuffer });
//       setDocumentText(result.value);
//       console.log(result.value);

//     } catch (error) {
//       console.error('Error converting .docx file to text:', error);
//     }
//   };

//   useEffect(() => {
//     const handleFileConversion = async () => {
//       if (savedDocument && chatId) {
//         const text = await convertDocxToText(savedDocument);
//         setConvertedFiles((prev) => ({ ...prev, [chatId]: text }));
//       }
//     };
//     handleFileConversion();
//   }, [savedDocument, chatId]);

//   const parseMarkdown = (text) => {
//     if (typeof text !== 'string') return '';

//     text = text.replace(/^# (.+)\s*$/gm, '<h1 style="color: black; font-weight: bold;">$1</h1>');
//     text = text.replace(/^### (.+)\s*$/gm, '<h3 style="color: black; font-weight: bold;">$1</h3>');
//     text = text.replace(/"(.*?)"/g, '<strong style="color: black;">"$1"</strong>');

//     text = text.replace(/\n/g, '<br/>');

//     return text;
//   };

//   return (
//     <>
//       <div className="chat pe-5">
//         <div className="kycWriting d-flex justify-content-between align-items-center">
//           <h3>{chatName}</h3>
//           <Buttoon
//             className="btn btn-primary"
//             minWidth={208}
//             minHeight={39}
//             text="Save Document"
//           ></Buttoon>
//         </div>
//         <hr />
//         <div className="chat-data d-flex flex-column justify-content-between">
//           <div className="chattname">
//             <img className="pageIcon" src={pageIcon} alt="PageIcon" />
//             <div className="chatting">
//               <h4>ChatKYC</h4>
//               <h5>Document Completeness:</h5>
//               <p dangerouslySetInnerHTML={{ __html: parseMarkdown(documentText) }} />
//               <div className="d-flex gap-2 mt-4">
//                 <span
//                   className={`icon-container ${clickedIcons.like ? "clicked cursor-pointer" : "unclicked cursor-pointer"}`}
//                   onClick={() => handleClick("like")}
//                 >
//                   <Icon icon="iconamoon:like" width="20" height="20" />
//                 </span>

//                 <span
//                   className={`icon-container ${clickedIcons.heart ? "clicked cursor-pointer" : "unclicked cursor-pointer"}`}
//                   onClick={() => handleClick("heart")}
//                 >
//                   <Icon icon="mdi:heart-outline" width="20" height="20" />
//                 </span>

//                 <span
//                   className={`icon-container ${clickedIcons.dislike ? "clicked cursor-pointer" : "unclicked cursor-pointer"}`}
//                   onClick={() => handleClick("dislike")}
//                 >
//                   <Icon icon="iconamoon:dislike" width="20" height="20" />
//                 </span>
//               </div>
//             </div>
//           </div>
//           <hr />
//         </div>
//         <DashboardChatArea />
//       </div>
//     </>
//   );
// };

// export default AmlPolicy;
