import React, { useState } from "react";
import eyeOpen from "../../assets/icons/eye-opeen.png";
import eyeClose from "../../assets/icons/eye-close.svg";
import { Modal } from "antd";
import Input from "../../shared/input";
import Buttoon from "../../components/buttoon";
import axios from "axios";
import Swal from "sweetalert2";

const ChangepassModal = ({ ispswdmodal, setispswdmodal, fetchLoginUser }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setispswdmodal(false);
    console.log("Modal should close now");
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      Swal.fire({
        title: "Error",
        text: "New password and confirm password do not match",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/update_password`,
        { currentPassword, newPassword, confirmPassword }, // Include confirmPassword
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Password updated successfully", response.data);
      setLoading(false);
      fetchLoginUser(); // Refresh user data
      handleCancel();
      Swal.fire({
        title: "Success",
        text: "Your password has been updated successfully!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Error updating password:", error);
      setLoading(false);
      if (error.response && error.response.status === 400) {
        Swal.fire({
          title: "Error",
          text: "Current password is incorrect",
          icon: "error",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "There was an error updating your password. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  return (
    <>
      <Modal
        open={ispswdmodal}
        className="credit-modal"
        centered={true}
        width={880}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="modal-content passContent">
          <div className="pass-modal-header w-100">
            <h4 className="fw-bold">Change Password</h4>
          </div>
          <div className="pass-modal-content">
            <div className="confirm-pass-container w-100 d-flex justify-content-between">
              <div className="c-pass-div h-center justify-content-start flex-column gap-2 mt-4">
                <label className="fw-bold" htmlFor="">
                  Current Password
                </label>
                <Input
                  height="46px"
                  type="password"
                  className="curnt-pass"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  icon={eyeClose}
                />
              </div>
            </div>
            <div className="confirm-pass-container w-100 d-flex justify-content-between">
              <div className="c-pass-div h-center justify-content-start flex-column gap-2 mt-4">
                <label className="fw-bold" htmlFor="">
                  Enter New Password
                </label>
                <Input
                  height="46px"
                  type="password"
                  className="curnt-pass"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  icon={eyeClose}
                />
              </div>
              <div className="c-pass-div h-center justify-content-start flex-column gap-2 mt-4">
                <label className="fw-bold" htmlFor="">
                  Confirm New Password
                </label>
                <Input
                  height="46px"
                  type="password"
                  className="curnt-pass"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  icon={eyeClose}
                />
              </div>
            </div>
            <div className="h-center">
              <Buttoon
                className="btn btn-primary mt-5 m-auto"
                minWidth={166}
                minHeight={43}
                text="Save"
                onClick={handleChangePassword}
                loading={loading}
              ></Buttoon>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ChangepassModal;
