import React, { useState, useEffect, useRef } from "react";
import "./pages/home/DashboardChatArea.css";
import { useNavigate, useParams } from "react-router-dom";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { Modal } from "antd";
import { Icon } from "@iconify/react";
import Buttoon from "../components/buttoon";
import verifyUser from "../middleware/verifyUser";
import chatKycLogo from "../assets/icons/kycLogo.svg";
import promptClip from "../assets/icons/promptClip.svg";
import paperPlane from "../assets/icons/paperPlain.svg";
import chatLoggo from "../assets/icons/dashLogo.png";

const DashboardChatArea = ({ setShowContent }) => {
  const [chatMessage, setChatMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [userInitials, setUserInitials] = useState("");
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isUser, setIsUser] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [userId, setUserId] = useState("");
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const chatEndRef = useRef(null);
  const isTabActiveRef = useRef(true);
  const typingEffectIntervalRef = useRef(null);
  const [hasChatHistory, setHasChatHistory] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [ChatName, setChatName] = useState(null);

  const { id: chatId } = useParams();
  const navigate = useNavigate();

  const loadChatHistoryFromLocalStorage = (chatId) => {
    const savedHistory = localStorage.getItem(`chatHistory-${chatId}`);
    return savedHistory ? JSON.parse(savedHistory) : [];
  };

  useEffect(() => {
    const savedHistory = loadChatHistoryFromLocalStorage(chatId);
    if (savedHistory.length > 0) {
      setChatHistory(savedHistory);
      setHasChatHistory(true);
      setShowContent(false);
    } else {
      fetchChatHistory(chatId);
    }

    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [chatId]);

  useEffect(() => {
    const loginUserId = localStorage.getItem("userId");
    setUserId(loginUserId);

    const checkUser = async () => {
      const result = await verifyUser();
      if (!result) {
        navigate("/login");
      } else {
        setIsUser(true);
      }
    };
    checkUser();

    const name = localStorage.getItem("name");
    if (name) {
      const nameParts = name.split(" ");
      const initials =
        nameParts[0][0].toUpperCase() +
        (nameParts[1] ? nameParts[1][0].toUpperCase() : "");
      setUserInitials(initials);
    }
  }, [chatId, navigate]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatHistory]);

  const fetchChatHistory = async (chatId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/chatApi/chat/${chatId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const fetchedHistory = response.data.chats;
      console.log(response.data.chats);

      const formattedHistory = fetchedHistory.flatMap((chat) =>
        chat.conversations.map((conversation) => ({
          user: conversation.user || "",
          bot: conversation.bot || "",
          timestamp: conversation.timestamp || "",
        }))
      );

      setChatHistory(formattedHistory);
      setShowContent(formattedHistory.length === 0);
    } catch (error) {
      console.error("Error fetching chat history:", error);
      message.error("Failed to fetch chat history.");
    }
  };

  const handleSaveDocument = async () => {
    setIsConfirmationModalOpen(false);

    const saveQuery = { user: "Save the document", bot: "Processing..." };
    setChatHistory((prevHistory) => [...prevHistory, saveQuery]);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_PHYTHON}/save_document`,
        {
          params: { chat_id: chatId, user_id: userId },
          headers: { "Content-Type": "application/json" },
        }
      );

      const botResponse = response.data.filename;
      if (botResponse) {
        setChatHistory((prevHistory) => {
          const updatedHistory = [...prevHistory];
          updatedHistory[updatedHistory.length - 1] = {
            user: "Save the document",
            bot: `<span style="color:green;"><strong>✔️ Document Saved.</strong></span> <a href="${process.env.REACT_APP_BASE_URL_PHYTHON}/uploads/${botResponse}" download>Download Document</a>`,
          };
          return updatedHistory;
        });
        message.success("Document saved successfully!");
      } else {
        setChatHistory((prevHistory) => {
          const updatedHistory = [...prevHistory];
          updatedHistory[updatedHistory.length - 1] = {
            user: "Save the document",
            bot: "Error: Failed to save document. Please try again.",
          };
          return updatedHistory;
        });
        message.error("Failed to save document.");
      }
    } catch (error) {
      message.error("Failed to save document.");
      console.error("Error saving document:", error);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
      message.success(`${files.length} file(s) selected.`);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
      setChatMessage("");
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        isTabActiveRef.current = false;
      } else {
        isTabActiveRef.current = true;
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const cancelFileUpload = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const MAX_FILE_SIZE = 50 * 1024 * 1024;
  const handleSendMessage = async () => {
    const largeFiles = selectedFiles.filter(
      (file) => file.size > MAX_FILE_SIZE
    );

    if (largeFiles.length > 0) {
      message.error(
        `File(s) too large. Maximum allowed size is ${
          MAX_FILE_SIZE / (1024 * 1024)
        } MB.`
      );
      return;
    }

    if (selectedFiles.length === 0 && chatMessage.trim() === "") {
      message.warning("Please enter a message or select a file to upload.");
      return;
    }

    const currentMessageIndex = chatHistory.length;
    let newHistoryItem = {
      user: "",
      bot: "",
    };

    if (selectedFiles.length > 0 && chatMessage.trim() !== "") {
      const fileNames = selectedFiles.map((file) => file.name).join(", ");
      newHistoryItem.user = `${fileNames}\n${chatMessage}`;
    } else if (selectedFiles.length > 0) {
      newHistoryItem.user = selectedFiles.map((file) => file.name).join(", ");
    } else if (chatMessage.trim() !== "") {
      newHistoryItem.user = chatMessage;
    }

    setChatHistory((prevHistory) => [...prevHistory, newHistoryItem]);
    setLoadingIndex(currentMessageIndex);
    setShowContent(false);

    try {
      if (selectedFiles.length > 0) {
        const formData = new FormData();
        selectedFiles.forEach((file) => formData.append("file", file));
        formData.append("chat_id", chatId);
        formData.append("user_id", userId);
        formData.append("query", chatMessage || "");

        const fileUploadResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL_PHYTHON}/upload_document`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
            // onUploadProgress: (progressEvent) => {
            //   const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            //   setProgress(percentCompleted);
            // },
          }
        );

        const botMessage = fileUploadResponse.data.message;
        const botResponse = fileUploadResponse.data.response;

        setChatHistory((prevHistory) => {
          const updatedHistory = [...prevHistory];
          updatedHistory[currentMessageIndex].bot = botResponse;
          return updatedHistory;
        });
        setUploading(false);
        setLoadingIndex(null);
        setSelectedFiles([]); // Clear the selected files
        resetFileInput(); // Reset file input field
        setChatMessage("");
      }

      if (chatMessage.trim() !== "" && selectedFiles.length === 0) {
        const payload = {
          chat_id: chatId,
          prompt: chatMessage,
          user_id: userId,
        };

        const chatResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL_PHYTHON}/chat`,
          payload,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        const botResponse = chatResponse.data.response;
        let responseIndex = 0;

        const typingSpeed = 5;

        typingEffectIntervalRef.current = setInterval(() => {
          setChatHistory((prevHistory) => {
            const updatedArray = [...prevHistory];
            updatedArray[currentMessageIndex].bot = botResponse.substring(
              0,
              responseIndex
            );
            return updatedArray;
          });
          responseIndex++;

          if (responseIndex > botResponse.length) {
            clearInterval(typingEffectIntervalRef.current);
            typingEffectIntervalRef.current = null;
            setLoadingIndex(null);
            chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
          }
        }, typingSpeed);
        // let responseIndex = 0;

        // typingEffectIntervalRef.current = setInterval(() => {
        //   setChatHistory((prevHistory) => {
        //     const updatedArray = [...prevHistory];
        //     updatedArray[currentMessageIndex].bot = botResponse.substring(0, responseIndex);
        //     return updatedArray;
        //   });
        //   responseIndex++;
        //   if (responseIndex > botResponse.length) {
        //     clearInterval(typingEffectIntervalRef.current);
        //     typingEffectIntervalRef.current = null;
        //     setLoadingIndex(null);
        //     chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        //   }
        // }, 15);

        setChatMessage("");
        localStorage.setItem(
          `chatHistory-${chatId}`,
          JSON.stringify([
            ...chatHistory,
            { user: chatMessage, bot: botResponse },
          ])
        ); // Update localStorage
      }
    } catch (error) {
      setUploading(false);
      setLoadingIndex(null);
      message.error("Failed to process the request. Please try again.");
      console.error("Error uploading file or sending message:", error);
    }
  };

  const resetFileInput = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const parseMarkdown = (text) => {
    if (typeof text !== "string") return "";

    // Convert links
    text = text.replace(
      /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g,
      '<a href="$2" target="_blank" style="color: blue; text-decoration: underline;">$1</a>'
    );

    // Convert headers
    text = text.replace(
      /^# (.+)\s*$/gm,
      '<h1 class="h1" style="color: black; font-weight: normal;">$1</h1>'
    );
    text = text.replace(
      /^## (.+)\s*$/gm,
      '<h2 class="h2" style="color: black; font-weight: normal;"><strong>$1</strong></h2>'
    );
    text = text.replace(
      /^### (.+)\s*$/gm,
      '<h3 class="h3" style="color: black; font-weight: normal;"><strong>$1</strong></h3>'
    );
    text = text.replace(
      /^#### (.+)\s*$/gm,
      '<h4 class="h4" style="color: black; font-weight: bold;">$1</h4>'
    );

    // Bold and Italic text
    text = text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
    text = text.replace(/\*(.*?)\*/g, "<em>$1</em>");

    // Lists
    text = text.replace(/-\s(.+?)\n/g, "<li>$1</li>");

    // Detect comparison sections dynamically
    const comparisonRegex = /Category:\s*(.+?)\n(.+?)\n(.+?)\n/gm;
    text = text.replace(
      comparisonRegex,
      (match, category, entity1, entity2) => {
        // Extract category, entity1, and entity2
        let entity1Data = entity1.split(":");
        let entity2Data = entity2.split(":");

        // If the data matches the pattern, create a table
        if (entity1Data.length === 2 && entity2Data.length === 2) {
          let entity1Title = entity1Data[0].trim();
          let entity1Value = entity1Data[1].trim();
          let entity2Title = entity2Data[0].trim();
          let entity2Value = entity2Data[1].trim();

          return `
                <table style="border-collapse: collapse; width: 100%; margin-top: 10px;">
                    <thead>
                        <tr>
                            <th style="border: 1px solid black; padding: 8px; text-align: left;">Category</th>
                            <th style="border: 1px solid black; padding: 8px; text-align: left;">${entity1Title}</th>
                            <th style="border: 1px solid black; padding: 8px; text-align: left;">${entity2Title}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="border: 1px solid black; padding: 8px;">${category.trim()}</td>
                            <td style="border: 1px solid black; padding: 8px;">${entity1Value}</td>
                            <td style="border: 1px solid black; padding: 8px;">${entity2Value}</td>
                        </tr>
                    </tbody>
                </table>
            `;
        }
        return match; // if doesn't match, return the original text
      }
    );

    // Table conversion for Markdown style tables
    const tableRegex = /\|(.+)\|\n\|(:?-+:?\|)+\n((\|.+?\|\n)+)/g;
    text = text.replace(tableRegex, (match) => {
      const rows = match.trim().split("\n");
      let headerCells = rows[0].split("|").filter((cell) => cell.trim());
      let bodyRows = rows
        .slice(2)
        .map((row) => row.split("|").filter((cell) => cell.trim()));

      // Generate HTML table
      let tableHtml = '<table style="border-collapse: collapse; width: 100%;">';

      // Header row
      tableHtml += "<thead><tr>";
      headerCells.forEach((cell) => {
        tableHtml += `<th style="border: 1px solid black; padding: 8px; text-align: left;">${cell.trim()}</th>`;
      });
      tableHtml += "</tr></thead>";

      // Body rows
      tableHtml += "<tbody>";
      bodyRows.forEach((row) => {
        tableHtml += "<tr>";
        row.forEach((cell) => {
          tableHtml += `<td style="border: 1px solid black; padding: 8px;">${cell.trim()}</td>`;
        });
        tableHtml += "</tr>";
      });
      tableHtml += "</tbody></table>";

      return tableHtml;
    });

    // Convert line breaks
    text = text.replace(/\n/g, "<br/>");

    return text;
  };

  useEffect(() => {
    let isMounted = true;

    const getName = async () => {
      if (chatId) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/chatApi/chat`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          const updatedChat = response.data?.data?.find(
            (chat) => chat.chat_id === chatId
          );

          if (isMounted && updatedChat && updatedChat.name) {
            setChatName(updatedChat.name);
          }
        } catch (error) {
          console.error("Error fetching the name of the chat:", error);
        }
      }
    };

    getName();

    return () => {
      isMounted = false;
    };
  }, [chatId]);

  const showSaveDocumentButton = chatHistory.length > 0;
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <>
      {chatHistory.length > 0 && (
        <div
          className="kycWriting d-flex justify-content-between align-items-center  border-bottom border-3"
          // style={{ paddingBottom: "10px", paddingTop: "20px" }}
        >
          {chatHistory.length > 0 && !isMobile && <h3>{ChatName}</h3>}
          {showSaveDocumentButton && (
            <Buttoon
              onClick={handleSaveDocument}
              className="btn btn-primary"
              text="Save Document"
              style={{
                marginLeft: isMobile ? "auto" : "0",
                marginBottom: "20px",
              }}
            />
          )}
        </div>
      )}

      <div className="chatArea">
        <div className="chatWithBot">
          {chatHistory.map((chat, index) => (
            <div
              key={index}
              className={`chatMessageContainer ${
                chat.user ? "userMessage" : "botMessage"
              }`}
            >
              {chat.user && <div className="userInitials">{userInitials}</div>}
              <div className={`chatBubble ${chat.user ? "" : "botChatBubble"}`}>
                <p
                  style={{
                    margin: 0,
                    opacity: index === loadingIndex ? 0.5 : 1,
                  }}
                >
                  {chat.user ? (
                    <>
                      {chat.user}
                      {index === loadingIndex && (
                        <div
                          style={{
                            display: "inline-block",
                            marginLeft: "13px",
                          }}
                        >
                          <Spin indicator={antIcon} className="loader-spin" />
                        </div>
                      )}
                    </>
                  ) : (
                    chat.bot
                  )}
                </p>
              </div>
              {chat.bot && (
                <div className="botResponse">
                  <img
                    style={{ width: "50px", objectFit: "cover" }}
                    className="botMessageLogo"
                    src={chatLoggo}
                    alt="ChatKYC Logo"
                  />
                  <div className="botChatBubble">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: parseMarkdown(chat.bot),
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
          <div ref={chatEndRef} />
        </div>

        <div className="promptDiv">
          {selectedFiles.length > 0 && (
            <div className="selectedFileName ">
              {selectedFiles.map((file, index) => (
                <div className="filesDesign d-flex gap-2 justify-content-center align-items-center">
                  <div className="attachment-files">
                    <p key={index}>{file.name}</p>
                  </div>

                  <Icon
                    icon="radix-icons:cross-1"
                    width="12"
                    height="12"
                    color="red"
                    onClick={() => cancelFileUpload(index)}
                  />
                </div>
              ))}
            </div>
          )}
          <div className="promptArea1">
            <label htmlFor="fileInput">
              <img className="cursor-pointer" src={promptClip} alt="Upload" />
            </label>
            <input
              id="fileInput"
              type="file"
              accept=".pdf,.csv,.pptx,.docx,.txt"
              onChange={handleFileChange}
              multiple
              style={{ display: "none" }}
            />
          </div>
          <div className="promptArea2">
            <input
              className="text-med"
              type="text"
              placeholder="Message ChatKYC"
              value={chatMessage}
              onKeyPress={handleKeyPress}
              onChange={(e) => setChatMessage(e.target.value)}
            />
          </div>
          <div className="promptArea3">
            <img
              className="cursor-pointer"
              src={paperPlane}
              alt="Send"
              onClick={handleSendMessage}
            />
          </div>
        </div>
      </div>
      <Modal
        open={isConfirmationModalOpen}
        onOk={handleSaveDocument}
        onCancel={() => setIsConfirmationModalOpen(false)}
        footer={null}
        className="custom-confirmation-modal"
      >
        <div style={{ textAlign: "center", padding: "20px" }}>
          <p style={{ fontSize: "16px", fontWeight: "bold" }}>
            Do you want to save this document?
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              marginTop: "20px",
            }}
          >
            <Buttoon
              className="btn btn-primary"
              text="Create Document"
              onClick={handleSaveDocument}
            />
            <Buttoon
              className="btn btn-secondary"
              text="Cancel"
              onClick={() => setIsConfirmationModalOpen(false)}
            />
          </div>
        </div>
      </Modal>
      {apiResponse && (
        <div className="apiResponse">
          <p>{apiResponse}</p>
        </div>
      )}
    </>
  );
};

export default DashboardChatArea;

// import React, { useState, useEffect, useRef } from "react";
// import './pages/home/DashboardChatArea.css';
// import { useNavigate, useParams } from 'react-router-dom';
// import { message, Spin } from 'antd';
// import { LoadingOutlined } from '@ant-design/icons';
// import axios from 'axios';
// import { Modal } from 'antd';
// import Buttoon from '../components/buttoon';
// import verifyUser from '../middleware/verifyUser';
// import chatKycLogo from "../assets/icons/kycLogo.svg";
// import promptClip from "../assets/icons/promptClip.svg";
// import paperPlane from "../assets/icons/paperPlain.svg";

// const DashboardChatArea = ({ setShowContent }) => {
//   const [chatMessage, setChatMessage] = useState('');
//   const [chatHistory, setChatHistory] = useState([]);
//   const [loadingIndex, setLoadingIndex] = useState(null);
//   const [userInitials, setUserInitials] = useState('');
//   const [uploading, setUploading] = useState(false);
//   const [progress, setProgress] = useState(0);
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [isUser, setIsUser] = useState(false);
//   const [selectedFiles, setSelectedFiles] = useState([]);
//   const [userId, setUserId] = useState('');
//   const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
//   const [apiResponse, setApiResponse] = useState(null);
//   const chatEndRef = useRef(null);
//   const isTabActiveRef = useRef(true);
//   const typingEffectIntervalRef = useRef(null);
//   const [hasChatHistory, setHasChatHistory] = useState(false);
//   const [isMobile, setIsMobile] = useState(false);
//   const [ChatName, setChatName] = useState(null);

//   const { id: chatId } = useParams();
//   const navigate = useNavigate();

//   // Save chat history to local storage when updated
//   const saveChatHistoryToLocalStorage = (chatId, chatHistory) => {
//     localStorage.setItem(`chatHistory-${chatId}`, JSON.stringify(chatHistory));
//   };

//   const loadChatHistoryFromLocalStorage = (chatId) => {
//     const savedHistory = localStorage.getItem(`chatHistory-${chatId}`);
//     return savedHistory ? JSON.parse(savedHistory) : [];
//   };

//   useEffect(() => {
//     const savedHistory = loadChatHistoryFromLocalStorage(chatId);
//     if (savedHistory.length > 0) {
//       setChatHistory(savedHistory);
//       setHasChatHistory(true);
//       setShowContent(false);
//     } else {
//       fetchChatHistory(chatId);
//     }

//     const handleResize = () => setIsMobile(window.innerWidth <= 768);
//     handleResize();
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, [chatId]);

//   useEffect(() => {
//     const loginUserId = localStorage.getItem('userId');
//     setUserId(loginUserId);

//     const checkUser = async () => {
//       const result = await verifyUser();
//       if (!result) {
//         navigate('/login');
//       } else {
//         setIsUser(true);
//       }
//     };
//     checkUser();

//     const name = localStorage.getItem('name');
//     if (name) {
//       const nameParts = name.split(' ');
//       const initials = nameParts[0][0].toUpperCase() + (nameParts[1] ? nameParts[1][0].toUpperCase() : '');
//       setUserInitials(initials);
//     }
//   }, [chatId, navigate]);

//   useEffect(() => {
//     chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
//   }, [chatHistory]);

//   const fetchChatHistory = async (chatId) => {
//     try {
//       const token = localStorage.getItem('token');
//       const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/chatApi/chat/${chatId}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       const fetchedHistory = response.data.chats;

//       const formattedHistory = fetchedHistory.flatMap((chat) =>
//         chat.conversations.map((conversation) => ({
//           user: conversation.user || '',
//           bot: conversation.bot || '',
//           timestamp: conversation.timestamp || '',
//         }))
//       );

//       setChatHistory(formattedHistory);
//       saveChatHistoryToLocalStorage(chatId, formattedHistory); // Store in localStorage
//       setHasChatHistory(formattedHistory.length > 0);
//       setShowContent(formattedHistory.length === 0);
//     } catch (error) {
//       console.error('Error fetching chat history:', error);
//       message.error('Failed to fetch chat history.');
//     }
//   };

//   const handleSaveDocument = async () => {
//     setIsConfirmationModalOpen(false);

//     const saveQuery = { user: "Save the document", bot: 'Processing...' };
//     setChatHistory((prevHistory) => [...prevHistory, saveQuery]);

//     try {
//       const response = await axios.get(`${process.env.REACT_APP_BASE_URL_PHYTHON}/save_document`, {
//         params: { chat_id: chatId, user_id: userId },
//         headers: { 'Content-Type': 'application/json' },
//       });

//       const botResponse = response.data.filename;
//       if (botResponse) {
//         setChatHistory((prevHistory) => {
//           const updatedHistory = [...prevHistory];
//           updatedHistory[updatedHistory.length - 1] = {
//             user: "Save the document",
//             bot: `<span style="color:green;"><strong>✔️ Document Saved.</strong></span> <a href="${process.env.REACT_APP_BASE_URL_PHYTHON}/uploads/${botResponse}" download>Download Document</a>`,
//           };
//           return updatedHistory;
//         });
//         message.success('Document saved successfully!');
//       } else {
//         setChatHistory((prevHistory) => {
//           const updatedHistory = [...prevHistory];
//           updatedHistory[updatedHistory.length - 1] = {
//             user: "Save the document",
//             bot: "Error: Failed to save document. Please try again."
//           };
//           return updatedHistory;
//         });
//         message.error('Failed to save document.');
//       }
//     } catch (error) {
//       message.error('Failed to save document.');
//       console.error('Error saving document:', error);
//     }
//   };

//   // Shift+Enter for adding a new line, Enter for sending the message
//   const handleKeyPress = (e) => {
//     if (e.key === 'Enter' && !e.shiftKey) {
//       e.preventDefault();
//       handleSendMessage();
//       setChatMessage('');
//     }
//   };

//   useEffect(() => {
//     const handleVisibilityChange = () => {
//       if (document.hidden) {
//         isTabActiveRef.current = false;
//       } else {
//         isTabActiveRef.current = true;
//       }
//     };

//     document.addEventListener('visibilitychange', handleVisibilityChange);

//     return () => {
//       document.removeEventListener('visibilitychange', handleVisibilityChange);
//     };
//   }, []);

//   // File Upload and API Call handling
//   const MAX_FILE_SIZE = 50 * 1024 * 1024;
//   const handleSendMessage = async () => {
//     const largeFiles = selectedFiles.filter(file => file.size > MAX_FILE_SIZE);

//     if (largeFiles.length > 0) {
//       message.error(`File(s) too large. Maximum allowed size is ${MAX_FILE_SIZE / (1024 * 1024)} MB.`);
//       return;
//     }

//     if (selectedFiles.length === 0 && chatMessage.trim() === '') {
//       message.warning('Please enter a message or select a file to upload.');
//       return;
//     }

//     const currentMessageIndex = chatHistory.length;
//     let newHistoryItem = {
//       user: '',
//       bot: '',
//     };

//     if (selectedFiles.length > 0 && chatMessage.trim() !== '') {
//       const fileNames = selectedFiles.map(file => file.name).join(', ');
//       newHistoryItem.user = `${fileNames}\n${chatMessage}`;
//     } else if (selectedFiles.length > 0) {
//       newHistoryItem.user = selectedFiles.map(file => file.name).join(', ');
//     } else if (chatMessage.trim() !== '') {
//       newHistoryItem.user = chatMessage;
//     }

//     setChatHistory((prevHistory) => [...prevHistory, newHistoryItem]);
//     setLoadingIndex(currentMessageIndex);
//     setShowContent(false);

//     try {
//       if (selectedFiles.length > 0) {
//         const formData = new FormData();
//         selectedFiles.forEach(file => formData.append('file', file));
//         formData.append('chat_id', chatId);
//         formData.append('user_id', userId);
//         formData.append('query', chatMessage || '');

//         const fileUploadResponse = await axios.post(
//           `${process.env.REACT_APP_BASE_URL_PHYTHON}/upload_document`,
//           formData,
//           {
//             headers: { 'Content-Type': 'multipart/form-data' },
//             onUploadProgress: (progressEvent) => {
//               const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
//               setProgress(percentCompleted);
//             },
//           }
//         );

//         const botMessage = fileUploadResponse.data.message;
//         const botResponse = fileUploadResponse.data.response;

//         setChatHistory((prevHistory) => {
//           const updatedHistory = [...prevHistory];
//           updatedHistory[currentMessageIndex].bot = botResponse;
//           return updatedHistory;
//         });
//         setUploading(false);
//         setSelectedFiles([]); // Clear the selected files
//         resetFileInput(); // Reset file input field
//         setChatMessage('');
//       }

//       if (chatMessage.trim() !== '' && selectedFiles.length === 0) {
//         const payload = {
//           chat_id: chatId,
//           prompt: chatMessage,
//           user_id: userId,
//         };

//         const chatResponse = await axios.post(
//           `${process.env.REACT_APP_BASE_URL_PHYTHON}/chat`,
//           payload,
//           {
//             headers: { 'Content-Type': 'application/json' },
//           }
//         );

//         const botResponse = chatResponse.data.response;
//         let responseIndex = 0;

//         typingEffectIntervalRef.current = setInterval(() => {
//           setChatHistory((prevHistory) => {
//             const updatedArray = [...prevHistory];
//             updatedArray[currentMessageIndex].bot = botResponse.substring(0, responseIndex);
//             return updatedArray;
//           });
//           responseIndex++;
//           if (responseIndex > botResponse.length) {
//             clearInterval(typingEffectIntervalRef.current);
//             typingEffectIntervalRef.current = null;
//             setLoadingIndex(null);
//             chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
//           }
//         }, 15);

//         setChatMessage('');
//         saveChatHistoryToLocalStorage(chatId, [...chatHistory, { user: chatMessage, bot: botResponse }]);  // Update localStorage
//       }
//     } catch (error) {
//       setUploading(false);
//       setLoadingIndex(null);
//       message.error('Failed to process the request. Please try again.');
//       console.error('Error uploading file or sending message:', error);
//     }
//   };

//   const resetFileInput = () => {
//     const fileInput = document.getElementById('fileInput');
//     if (fileInput) {
//       fileInput.value = '';
//     }
//   };

//   const parseMarkdown = (text) => {
//     if (typeof text !== 'string') return '';

//     // Convert links
//     text = text.replace(/\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g, '<a href="$2" target="_blank" style="color: blue; text-decoration: underline;">$1</a>');

//     // Convert headers
//     text = text.replace(/^# (.+)\s*$/gm, '<h1 class="h1" style="color: black; font-weight: normal;">$1</h1>');
//     text = text.replace(/^## (.+)\s*$/gm, '<h2 class="h2" style="color: black; font-weight: normal;"><strong>$1</strong></h2>');
//     text = text.replace(/^### (.+)\s*$/gm, '<h3 class="h3" style="color: black; font-weight: normal;"><strong>$1</strong></h3>');
//     text = text.replace(/^#### (.+)\s*$/gm, '<h4 class="h4" style="color: black; font-weight: bold;">$1</h4>');

//     // Bold and Italic text
//     text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
//     text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');

//     // Lists
//     text = text.replace(/-\s(.+?)\n/g, '<li>$1</li>');

//     // Detect comparison sections dynamically
//     const comparisonRegex = /Category:\s*(.+?)\n(.+?)\n(.+?)\n/gm;
//     text = text.replace(comparisonRegex, (match, category, entity1, entity2) => {
//         // Extract category, entity1, and entity2
//         let entity1Data = entity1.split(':');
//         let entity2Data = entity2.split(':');

//         // If the data matches the pattern, create a table
//         if (entity1Data.length === 2 && entity2Data.length === 2) {
//             let entity1Title = entity1Data[0].trim();
//             let entity1Value = entity1Data[1].trim();
//             let entity2Title = entity2Data[0].trim();
//             let entity2Value = entity2Data[1].trim();

//             return `
//                 <table style="border-collapse: collapse; width: 100%; margin-top: 10px;">
//                     <thead>
//                         <tr>
//                             <th style="border: 1px solid black; padding: 8px; text-align: left;">Category</th>
//                             <th style="border: 1px solid black; padding: 8px; text-align: left;">${entity1Title}</th>
//                             <th style="border: 1px solid black; padding: 8px; text-align: left;">${entity2Title}</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         <tr>
//                             <td style="border: 1px solid black; padding: 8px;">${category.trim()}</td>
//                             <td style="border: 1px solid black; padding: 8px;">${entity1Value}</td>
//                             <td style="border: 1px solid black; padding: 8px;">${entity2Value}</td>
//                         </tr>
//                     </tbody>
//                 </table>
//             `;
//         }
//         return match; // if doesn't match, return the original text
//     });

//     // Table conversion for Markdown style tables
//     const tableRegex = /\|(.+)\|\n\|(:?-+:?\|)+\n((\|.+?\|\n)+)/g;
//     text = text.replace(tableRegex, (match) => {
//         const rows = match.trim().split('\n');
//         let headerCells = rows[0].split('|').filter(cell => cell.trim());
//         let bodyRows = rows.slice(2).map(row => row.split('|').filter(cell => cell.trim()));

//         // Generate HTML table
//         let tableHtml = '<table style="border-collapse: collapse; width: 100%;">';

//         // Header row
//         tableHtml += '<thead><tr>';
//         headerCells.forEach(cell => {
//             tableHtml += `<th style="border: 1px solid black; padding: 8px; text-align: left;">${cell.trim()}</th>`;
//         });
//         tableHtml += '</tr></thead>';

//         // Body rows
//         tableHtml += '<tbody>';
//         bodyRows.forEach(row => {
//             tableHtml += '<tr>';
//             row.forEach(cell => {
//                 tableHtml += `<td style="border: 1px solid black; padding: 8px;">${cell.trim()}</td>`;
//             });
//             tableHtml += '</tr>';
//         });
//         tableHtml += '</tbody></table>';

//         return tableHtml;
//     });

//     // Convert line breaks
//     text = text.replace(/\n/g, '<br/>');

//     return text;
// };

//   return (
//     <>
//       {chatHistory.length > 0 && (
//         <div className="kycWriting d-flex justify-content-between align-items-center m-2 border-bottom border-3" style={{ paddingBottom: '10px' }}>
//           {chatHistory.length > 0 && !isMobile && (
//             <h3>{ChatName}</h3>
//           )}
//           <Buttoon
//             onClick={handleSaveDocument}
//             className="btn btn-primary"
//             text="Save Document"
//             style={{ marginLeft: isMobile ? 'auto' : '0', marginBottom: '20px' }}
//           />
//         </div>
//       )}

//       <div className="chatArea pe-5">
//         <div className="chatWithBot">
//           {chatHistory.map((chat, index) => (
//             <div
//               key={index}
//               className={`chatMessageContainer ${chat.user ? 'userMessage' : 'botMessage'}`}
//             >
//               {chat.user && (
//                 <div className="userInitials">
//                   {userInitials}
//                 </div>
//               )}
//               <div className={`chatBubble ${chat.user ? '' : 'botChatBubble'}`}>
//                 <p style={{ margin: 0, opacity: index === loadingIndex ? 0.5 : 1 }}>
//                   {chat.user ? (
//                     <>
//                       {chat.user}
//                       {index === loadingIndex && (
//                         <div style={{ display: 'inline-block', marginLeft: '13px' }}>
//                           <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} className="loader-spin" />
//                         </div>
//                       )}
//                     </>
//                   ) : chat.bot}
//                 </p>
//               </div>
//               {chat.bot && (
//                 <div className="botResponse">
//                   <img className="botMessageLogo" src={chatKycLogo} alt="ChatKYC Logo" />
//                   <div className="botChatBubble">
//                     <p dangerouslySetInnerHTML={{ __html: parseMarkdown(chat.bot) }} />
//                   </div>
//                 </div>
//               )}
//             </div>
//           ))}
//           <div ref={chatEndRef} />
//         </div>

//         <div className="promptDiv">
//           {selectedFiles.length > 0 && (
//             <div className="selectedFileName">
//               {selectedFiles.map((file, index) => (
//                 <p key={index}>{file.name}</p>
//               ))}
//             </div>
//           )}
//           <div className="promptArea1">
//             <label htmlFor="fileInput">
//               <img className="cursor-pointer" src={promptClip} alt="Upload" />
//             </label>
//             <input
//               id="fileInput"
//               type="file"
//               accept=".pdf,.csv,.pptx,.docx,.txt"
//               onChange={handleFileChange}
//               multiple
//               style={{ display: 'none' }}
//             />
//           </div>
//           <div className="promptArea2">
//             <input
//               className="text-med"
//               type="text"
//               placeholder="Message ChatKYC"
//               value={chatMessage}
//               onKeyPress={handleKeyPress}
//               onChange={(e) => setChatMessage(e.target.value)}
//             />
//           </div>
//           <div className="promptArea3">
//             <img className="cursor-pointer" src={paperPlane} alt="Send" onClick={handleSendMessage} />
//           </div>
//         </div>
//       </div>

//       <Modal
//         open={isConfirmationModalOpen}
//         onOk={handleSaveDocument}
//         onCancel={() => setIsConfirmationModalOpen(false)}
//         footer={null}
//         className="custom-confirmation-modal"
//       >
//         <div style={{ textAlign: 'center', padding: '20px' }}>
//           <p style={{ fontSize: '16px', fontWeight: 'bold' }}>Do you want to save this document?</p>
//           <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
//             <Buttoon
//               className="btn btn-primary"
//               text="Create Document"
//               onClick={handleSaveDocument}
//             />
//             <Buttoon
//               className="btn btn-secondary"
//               text="Cancel"
//               onClick={() => setIsConfirmationModalOpen(false)}
//             />
//           </div>
//         </div>
//       </Modal>
//       {apiResponse && (
//         <div className="apiResponse">
//           <p>{apiResponse}</p>
//         </div>
//       )}
//     </>
//   );
// };

// export default DashboardChatArea;
