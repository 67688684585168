import React from "react";
import Heading from "../../../shared/heading";
import feature1icon from "../../../assets/icons/copyy.svg";
import simplePage from "../../../assets/icons/simpleNote.svg";
import riskPic from "../../../assets/icons/risk.svg";
import compilance from "../../../assets/icons/featureIcon.svg";
import featureRightPic from "../../../assets/images/home/feature.png";

const Features = () => {
  return (
    <>
      <div name="feature" className="featureSection">
        <Heading
          headingtext="Features"
          paraText="Explore the powerful features designed to streamline and enhance your financial crime compliance management."
        />
        <div className="container">
          <div className="innerArea">
            <div className="leftArea">
              <li>
                <img className="featureIcons" src={simplePage} alt="" />
                <div className="d-flev flex-column gap-4">
                  <h4 className="fw-semibold">
                    Simplified Regulation Summarization
                  </h4>
                  <p className="fw-normal text-large">
                    Transform dense regulatory texts into clear, concise
                    summaries with our cutting-edge AI. Stay compliant without
                    getting buried in paperwork
                  </p>
                </div>
              </li>

              <li>
                <img className="featureIcons" src={riskPic} alt="" />
                <div className="d-flev flex-column gap-4">
                  <h4 className="fw-semibold">Effortless Risk Assessments</h4>
                  <p className="fw-normal  text-large">
                    Proactively assess and manage regulatory risks with
                    customized tools designed for the non-tech savvy. Secure
                    your fintech operations with confidence
                  </p>
                </div>
              </li>

              <li>
                <img className="featureIcons" src={compilance} alt="" />
                <div className="d-flev flex-column gap-4">
                  <h4 className="fw-semibold">
                    Streamlined Compliance Reporting
                  </h4>
                  <p className="fw-normal  text-large">
                    Generate audit-ready reports with ease. ChatKYC automates
                    the grunt work, letting you focus on strategic compliance
                    planning.
                  </p>
                </div>
              </li>
            </div>
            <div className="rightArea">
              <img src={featureRightPic} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
