import React, { useState } from "react";
import { Button, Input, Modal, Select } from "antd";
import Buttoon from "../../components/buttoon";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
const { Option } = Select;
const ViewInfo = ({ isInfoModal, setIsInfoModal }) => {
  const navigate = useNavigate();

  const handleCancel = () => {
    setIsInfoModal(false);
    console.log("Modal should close now");
  };
  const handleStatusChange = (value) => {
    console.log("Selected status:", value);
  };

  const handleContactChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, "");
    setContact(numericValue);
  };
  const [username, setUsername] = useState("Your predefined username");
  const [email, setEmail] = useState("allison@gmail.com");
  const [fullName, setFullName] = useState("Allison Peter");
  const [contact, setContact] = useState("0331-3462962");
  const [depart, setDepart] = useState("Marketing");


  return (
    <>
      <Modal
        open={isInfoModal}
        className="credit-modal"
        centered={true}
        width={890}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="modal-content">
          <div className="pass-modal-header w-100">
            <h4 className="fw-bold">View/Edit Information</h4>
          </div>
          <div className="pass-modal-content">
            <div className="confirm-pass-container w-100 d-flex justify-content-between">
              <div className="c-pass-div h-center justify-content-start flex-column gap-2 mt-4">
                <label className=" fw-bold" htmlFor="">
                  Username
                </label>
                <Input
                  maxWidth="40px"
                  width="40px"
                  height="46px"
                  type="text"
                  className="curnt-pass"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                {/* <input style={{border:"1px solid gainsboro", height:"40px"}} className="w-100" type="text" />
                <Icon  icon="system-uicons:write" width="18" height="18"  style={{color: "black"}} /> */}
              </div>

              <div className="c-pass-div h-center justify-content-start flex-column gap-2 mt-4">
                <label className=" fw-bold" htmlFor="">
                  Email Address
                </label>
                <Input
                  maxWidth="40px"
                  width="40px"
                  height="46px"
                  type="email"
                  className="curnt-pass"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="confirm-pass-container w-100 d-flex justify-content-between">
              <div className="c-pass-div h-center justify-content-start flex-column gap-2 mt-4">
                <label className=" fw-bold" htmlFor="">
                  Full Name
                </label>
                <Input
                  maxWidth="40px"
                  width="40px"
                  height="46px"
                  type="text"
                  className="curnt-pass"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>

              <div className="c-pass-div h-center justify-content-start flex-column gap-2 mt-4">
                <label className=" fw-bold" htmlFor="">
                  Contact Number
                </label>
                <Input
                  maxWidth="40px"
                  width="40px"
                  height="46px"
                  type="phone"
                  className="curnt-pass"
                  value={contact}
                  onChange={handleContactChange}
                />
              </div>
            </div>

            <div className="confirm-pass-container w-100 d-flex justify-content-between">
              <div className="c-pass-div h-center justify-content-start flex-column gap-2 mt-4">
                <label className=" fw-bold" htmlFor="">
                  Department
                </label>
                <Input
                  maxWidth="40px"
                  width="40px"
                  height="46px"
                  type="text"
                  className="curnt-pass"
                  value={depart}
                  onChange={(e) => setDepart(e.target.value)}
                />
              </div>

              <div className="c-pass-div h-center justify-content-start flex-column gap-2 mt-4">
                <label className=" fw-bold" htmlFor="">
                  Status
                </label>
                <Select
                  maxWidth="40px"
                  width="40px"
                  height="76px"
                  className="curnt-pass selectInput"
                  onChange={handleStatusChange}
                  defaultValue="Active"
                >
                  <Option value="Active">Active</Option>
                  <Option value="Inactive">Inactive</Option>
                  <Option value="Suspend">Suspend</Option>
                </Select>
              </div>
            </div>
            <div className="h-center">
              <Buttoon
                className="btn btn-primary mt-5 m-auto"
                minWidth={166}
                minHeight={43}
                text="Save"
                onClick={() => {
                  handleCancel();
                }}
              ></Buttoon>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewInfo;
