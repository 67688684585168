import React from "react";
import { Link } from "react-router-dom";
import Buttoon from "../../buttoon";
import macc from "../../../assets/images/home/macbok.webp";
const Hero = () => {
  return (
    <>
      <div className="heroSection">
        <div className="topGradLayer"></div>
        <div className="container">
          <div className="inner v-center h-center flex-column">
            <h1 style={{ fontSize: "53px" }} className="fw-bold text-center">
              Your on-demand compliance SME, creating and improving your
              compliance policies and processes
            </h1>
            <h6 className="text-center">
              Empower your compliance with AI-driven insights. Simplify
              regulations, risk assessments <br />
              and audit preparations on one intuitive platform
            </h6>
            <Link to="/signup">
              <Buttoon
                className="btn btn-primary heroBtn"
                minWidth={210}
                minHeight={43}
                text="Get Started For Free"
              ></Buttoon>
            </Link>
            <img className="macbook" src={macc} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
