import React, { useState } from "react";
import leftPic from "../assets/images/accounts/loginPic.png";
import Buttoon from "../components/buttoon";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const Verify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");

  const handleChange = (OTP) => {
    setOtp(OTP);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = location.state || {};
    if (!email) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Email not provided'
      });
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/verify-otp`, { email, otp });
      Swal.fire({
        icon: 'success',
        title: 'OTP Verified',
        text: 'You can now reset your password.'
      });
      navigate("/change-password", { state: { email } });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response ? error.response.data.message : 'An error occurred'
      });
    }
  };

  return (
    <div className="loginWrapper">
      <div className="mainContainer">
        <div className="leftArea">
          <img src={leftPic} alt="" />
        </div>
        <div className="rightArea v-center h-center">
          <div className="midBox">
            <div className="loginTitle v-center h-center flex-column gap-2">
              <h3 className="fw-bold">Verification</h3>
              <p className="fw-semibold">Enter the verification code you received</p>
            </div>
            <form className="w-100" onSubmit={handleSubmit}>
              <div className="inputFieldContainer v-center h-center flex-column gap-3">
                <div className="v-center gap-3 pass-code">
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    inputStyle="input-element text-center"
                    numInputs={4}
                    renderSeparator={<span className="mx-2"></span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
              </div>
              <Buttoon
                className="btn btn-primary mb-1 mt-4 nextBtn"
                width={100}
                minWidth={360}
                minHeight={39}
                text="Next"
                type="submit"
              />
            </form>
            <div className="bottomSection v-center h-center flex-column gap-1">
              <p className="text-small fw-medium mt-0">
                Already have an account? <Link to="/login"><span className="text-primary text-underline">Login!</span></Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify;
