import React from 'react';
import { Outlet } from "react-router-dom";

const Dashboardoutlet = () => {
  return (
 <>
   <div>
      <Outlet />
    </div>
 </>
  )
}

export default Dashboardoutlet;