import React from "react";
import { Modal } from "antd";
import Buttoon from "../../components/buttoon";
import { Icon } from "@iconify/react/dist/iconify.js";

const DelCardModal = ({ isModalOpen, setIsModalOpen, onConfirm }) => {
  const handleConfirmDelete = () => {
    if (onConfirm) {
      onConfirm();
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={handleConfirmDelete}
        onCancel={handleCancel}
        className="del-modal"
        centered={true}
        footer={null}
      >
        <div className="modal-content v-center flex-column gap-3 py-3">
          <Icon
            icon="ep:delete"
            width="50"
            height="50"
            style={{ color: "black" }}
          />
          <h6 className="text-red text-center">
            Are you sure you want to delete?
          </h6>
          <div className="d-flex gap-3">
            <div className="h-center">
              <Buttoon
                className="btn btn-primary mt-3 m-auto"
                minWidth={100}
                minHeight={43}
                text="Delete"
                onClick={handleConfirmDelete}
              />
            </div>
            <div className="h-center">
              <Buttoon
                className="btn btn-secondary gray-btn mt-3 m-auto"
                minWidth={100}
                minHeight={43}
                text="Cancel"
                onClick={handleCancel} // Just close the modal
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DelCardModal;
