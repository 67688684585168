import React, { useState } from "react";
import { Modal, Input, Button } from "antd";

const RenameChatModal = ({ isModalOpen, setIsModalOpen, chatId, handleRenameChat }) => {
  const [newChatName, setNewChatName] = useState("");

  const handleOk = async () => {
    if (newChatName.trim() === "") return; 
    await handleRenameChat(chatId, newChatName);  
    setIsModalOpen(false);
    setNewChatName("");  
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setNewChatName(""); 
  };

  return (
    <Modal
      title="Rename Chat"
      visible={isModalOpen}
      onCancel={handleCancel}
      footer={null} 
    >
      <Input
        value={newChatName}
        onChange={(e) => setNewChatName(e.target.value)}
        placeholder="Enter new chat name"
      />
      {newChatName.trim() !== "" && (  // Conditionally render the button only if input is non-empty
        <Button type="primary" className="mt-2" onClick={handleOk}>
          Update Chat Name
        </Button>
      )}
    </Modal>
  );
};

export default RenameChatModal;
