import React from "react";
import Heading from "../../../shared/heading";
import Cards from "../../../shared/cards";
import gloww from "../../../assets/images/home/benefitGlow.webp";

const Benefits = () => {
  return (
    <>
      <div className="benfitSection">
        <img className="glow" src={gloww} alt="" />
        <div className="container">
        <Heading
          headingtext="Benefits"
          paraText="From enhanced efficiency to robust risk management, see how our solution can transform your compliance processes and support your organization's regulatory needs."
        />
        <div className="innerArea">
          <Cards />
        </div>
        </div>
    
      </div>
    </>
  );
};

export default Benefits;
