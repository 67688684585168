import React, { useState } from 'react';
import leftPic from "../assets/images/accounts/loginPic.png";
import Input from '../shared/input';
import Buttoon from '../components/buttoon';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const ChangePass = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [confirmpass, setConfirmPass] = useState("");
  const [errors, setErrors] = useState({});

  const validate = () => {
    const errors = {};
    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    }

    if (!confirmpass) {
      errors.confirmpass = "Confirm password is required";
    } else if (confirmpass !== password) {
      errors.confirmpass = "Passwords do not match";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const { email } = location.state || {};
      if (!email) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Email not provided'
        });
        return;
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/change-password`, { email, password });
        Swal.fire({
          icon: 'success',
          title: 'Password Changed',
          text: 'Your password has been updated successfully.'
        });
        navigate("/login");
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.response ? error.response.data.message : 'An error occurred'
        });
      }
    }
  };

  return (
    <div className="loginWrapper">
      <div className="mainContainer">
        <div className="leftArea">
          <img src={leftPic} alt="" />
        </div>
        <div className="rightArea v-center h-center">
          <div className="midBox">
            <div className="loginTitle v-center h-center flex-column gap-2">
              <h3 className='fw-bold'>Change Password</h3>
              <p className='fw-semibold'>Enter Your New Password</p>
            </div>
            <form className='w-100' onSubmit={handleSubmit}>
              <div className="inputFieldContainer v-center h-center flex-column gap-4 mt-3">
                <div className="emailBox h-center justify-content-start flex-column gap-2">
                  <label className='text-small fw-bold' htmlFor="">Enter New Password</label>
                  <Input
                    wrapperClass="w-100"
                    inputClass="fw-medium text-black pe-5"
                    value={password}
                    height="46px"
                    type="password"
                    icon
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {errors.password && (
                    <p className="error-message mt-1">{errors.password}</p>
                  )}
                </div>
                <div className="emailBox h-center justify-content-start flex-column gap-2">
                  <label className='text-small fw-bold' htmlFor="">Confirm New Password</label>
                  <Input
                    wrapperClass="w-100"
                    inputClass="fw-medium text-black pe-5"
                    value={confirmpass}
                    height="46px"
                    type="password"
                    icon
                    onChange={(e) => setConfirmPass(e.target.value)}
                  />
                  {errors.confirmpass && (
                    <p className="error-message mt-1">{errors.confirmpass}</p>
                  )}
                </div>
              </div>
              <Buttoon
                className="btn btn-primary mb-1 mt-4 nextBtn"
                width={100}
                minWidth={360}
                minHeight={39}
                text="Confirm"
                type="submit"
              />
            </form>
            <div className="bottomSection v-center h-center flex-column gap-3">
              <p className='text-small fw-normal'>Already have an account? <Link to="/login"><span className='text-primary text-underline'>Login!</span></Link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePass;
