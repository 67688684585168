import React, { useState } from "react";
import leftPic from "../assets/images/accounts/loginPic.png";
import Input from "../shared/input";
import Buttoon from "../components/buttoon";
import { Link , useNavigate } from "react-router-dom";
import google from "../assets/icons/google.svg";
import Swal from "sweetalert2";
import apple from "../assets/icons/apple.svg";

const Signup = () => {
  const navigate = useNavigate();
  const [formFields, setFormFields] = useState({
    name: "",
    email: "",
    password: "",
    confirmpass: ""
  });
  const [errors, setErrors] = useState({});

  const validate = () => {
    const errors = {};
  
    if (!formFields.name) {
      errors.name = "Name is required";
    } else if (formFields.name.trim().length < 3) {
      errors.name = "Name must be at least 3 characters long";
    } else if (/^\s*$/.test(formFields.name)) {
      errors.name = "Enter a valid name";
    }
  
    if (!formFields.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formFields.email)) {
      errors.email = "Email address is invalid";
    }
  
    if (!formFields.password) {
      errors.password = "Password is required";
    } else if (formFields.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    }
  
    if (!formFields.confirmpass) {
      errors.confirmpass = "Confirm password is required";
    } else if (formFields.confirmpass !== formFields.password) {
      errors.confirmpass = "Passwords do not match";
    }
  
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/signup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            username: formFields.name,
            email: formFields.email,
            password: formFields.password,
            confirmPassword: formFields.confirmpass
          })
        });

        const data = await response.text();
        if (response.ok) {
          Swal.fire({
            title: 'Signup Successful',
            text: 'You have successfully signed up!',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(() => {
            navigate("/login");
          });
        } else {
          Swal.fire({
            title: 'Signup Error',
            text: response.data.data,
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      } catch (error) {
        Swal.fire({
          title: 'Signup Error',
          text: 'An error occurred. Please try again.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    }
  };

  return (
    <>
      <div className="loginWrapper ">
        <div className="mainContainer bg=danger">
          <div className="leftArea">
            <img src={leftPic} alt="" />
          </div>
          <div className="rightArea v-center h-center">
            <div className="midBox">
              <div className="loginTitle  v-center h-center flex-column gap-2">
                <h3 className="fw-bold">Sign Up</h3>
                <p className="fw-semibold">
                  Create your account to continue to ChatKYC.
                </p>
              </div>
              <form className="w-100" action="">
                <div className="inputFieldContainer v-center h-center flex-column gap-3">
                  <div className="emailBox h-center justify-content-start flex-column gap-3">
                    <label className="text-small fw-bold" htmlFor="">
                      Enter your name
                    </label>
                    <Input
                      wrapperClass=" w-100"
                      inputClass="fw-medium text-black"
                      name="name"
                      value={formFields.name}
                      height="46px"
                      type="text"
                      onChange={handleInputChange}
                    />
                    {errors.name && <p className="error-message">{errors.name}</p>}
                  </div>

                  <div className="emailBox h-center justify-content-start flex-column gap-3">
                    <label className="text-small fw-bold" htmlFor="">
                      Enter your email
                    </label>
                    <Input
                      wrapperClass=" w-100"
                      inputClass="fw-medium text-black"
                      name="email"
                      value={formFields.email}
                      height="46px"
                      type="email"
                      onChange={handleInputChange}
                    />
                    {errors.email && <p className="error-message">{errors.email}</p>}
                  </div>

                  <div className="emailBox h-center justify-content-start flex-column gap-3">
                    <label className="text-small fw-bold" htmlFor="">
                      Enter password
                    </label>
                    <Input
                      wrapperClass=" w-100"
                      inputClass="fw-medium text-black pe-5"
                      name="password"
                      value={formFields.password}
                      height="46px"
                      type="password"
                      icon
                      onChange={handleInputChange}
                    />
                    {errors.password && <p className="error-message">{errors.password}</p>}
                  </div>

                  <div className="emailBox h-center justify-content-start flex-column gap-3">
                    <label className="text-small fw-bold" htmlFor="">
                      Confirm password
                    </label>
                    <Input
                      wrapperClass=" w-100"
                      inputClass="fw-medium text-black pe-5"
                      name="confirmpass"
                      value={formFields.confirmpass}
                      height="46px"
                      type="password"
                      icon
                      onChange={handleInputChange}
                    />
                    {errors.confirmpass && <p className="error-message">{errors.confirmpass}</p>}
                  </div>
                </div>

                <Buttoon
                  className="btn btn-primary mb-1 mt-4 nextBtn"
                  width={100}
                  minWidth={360}
                  minHeight={39}
                  text="Continue"
                  onClick={handleSubmit}
                ></Buttoon>
              </form>
              <div className="bottomSection v-center h-center flex-column gap-3 ">
                <div className="orLoginText v-center h-center w-100 gap-3">
                  <div className="grayLine"></div>
                  <p className="text-small fw-semibold">or signup with google</p>
                  <div className="grayLine"></div>
                </div>
                <div className="socialIconss v-center h-center w-100 gap-4 mt-2">
                  <img className="cursor-pointer" src={google} alt="" />
                  {/* <img className="cursor-pointer" src={apple} alt="" /> */}
                </div>
                <p className="alreadyAcc text-small fw-normal mt-4">
                  Already have an account?{" "}
                  <Link to="/login"> <span className="text-primary text-underline">Log In</span></Link>
                 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;

